import React from "react";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";

import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

export default function PortfolioSection(props) {
    const {
      port_content
    } = props;

    return(
      <>
        <div className="portfoliosect">
          
            <section className="section pt-0 pb-0">
                <div className="container">
                    <div className="section pt-0 pb-0">
                        <div className="columns is-gapless">

                            <div className="column is-half">
                                <div className="portfoliosect__copy py-6 pr-6 px-5">
                                    <div className="portfoliosect__main">
                                        <ReactMarkdown children={port_content.copy} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            </div>

                            <div className="column is-half p-0">
                                <div className="trustsection__imgs __bluecopy__blueimgs portfoliosect__imgs">
                                    {port_content.images.map((item, indx) => (
                                        <div 
                                            key={indx}  
                                            className={"trustsection__img trustsection__sideimg images_" + indx}
                                            style={{
                                                top: (100 / port_content.images.length * indx) + '%',
                                                height: (100 / port_content.images.length) + '%',
                                            }}
                                        >
                                            <PreviewCompatibleImage key={indx}_img imageInfo={item.img}/>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>
      </>
    );
}