import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import InteriorHero from "../components/interior-components/InteriorHero";
import PortfolioSection from "../components/interior-components/PortfolioSection";
import BlueSection from "../components/interior-components/BlueSection";
import QuoteSection from "../components/interior-components/QuoteSection";
import SimpleGallery from "../components/interior-components/SimpleGallery";
import { render } from "react-dom";

// eslint-disable-next-line
export const PortfolioPostTemplate = ({
  port_heroHeadline,
  port_title,
  port_showTitle,
  port_thirdLevel,
  port_heroImage,
  port_top,
  port_quote,
  port_bottom,
  port_blue,
  port_gal,
  contentComponent,
  content
}) => {
  const PostContent = contentComponent || Content;

  /**
   * TEMPLATE MARKUP START
   */
  return (
    <div className="main-page-content">
      {/* Required Blocks */}
      <InteriorHero image={port_heroImage} title={port_title} heroHeadline={port_heroHeadline} showTitle={port_showTitle} thirdLevel={port_thirdLevel} />
      
      {/* Optional Blocks */}
      { port_top.copy &&
         <PortfolioSection port_content={port_top} />
      }
      { port_quote.copy &&
        <QuoteSection image={port_quote.image} quote={port_quote.copy} />
      }
      { port_bottom.copy &&
        <PortfolioSection port_content={port_bottom} />
      }
      { port_blue.copy &&
        <BlueSection bluesection={port_blue} />
      }


      <section className="section pt-0 pb-0 not-porfolio-gallery-grey">
          <div className="container">
            <div className="section pt-0 pb-0">
              <div className="columns is-gapless">
                <div className="column">
                  <div className="py-6">
                    <PostContent className="content main-content mb-0" content={content} />
                    { port_gal && port_gal.length > 0 &&
                      <SimpleGallery images={port_gal} galleryID="gallery1" />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        

    </div>
  );
};

PortfolioPostTemplate.propTypes = {
  port_heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  port_title: PropTypes.string,
  port_showTitle: PropTypes.bool,
  port_thirdLevel: PropTypes.bool,
  port_heroHeadline: PropTypes.string,
  port_top: PropTypes.object,
  port_quote: PropTypes.object,
  port_bottom: PropTypes.object,
  port_blue: PropTypes.object,
  port_gal: PropTypes.object,
};

const PortfolioPost = ({ data }) => {
  const { markdownRemark: post } = data;
  

  return (
    <Layout>
      <PortfolioPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Portfolio">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        port_title={post.frontmatter.port_title}
        port_heroHeadline={post.frontmatter.port_heroHeadline}
        port_showTitle={post.frontmatter.port_showTitle}
        port_thirdLevel={post.frontmatter.port_thirdLevel}
        port_top={post.frontmatter.port_top}
        port_quote={post.frontmatter.port_quote}
        port_bottom={post.frontmatter.port_bottom}
        port_blue={post.frontmatter.port_blue}
        port_gal={post.frontmatter.port_gal}
        port_heroImage={post.frontmatter.port_heroImage}
      />
    </Layout>
  );
};


PortfolioPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default PortfolioPost;

export const pageQuery = graphql`
  query PortfolioQ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        port_title
        port_heroHeadline
        port_showTitle
        port_thirdLevel
        port_heroImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        port_top{
          images{
            img{
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
          }
          copy
        }
        port_top{
          images{
            img{
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
          }
          copy
        }
        port_quote{
          image{
            img{
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
          }
          copy
        }
        port_bottom{
          images{
            img{
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
          }
          copy
        }
        port_blue{
          heading
          copy
          images{
            img{
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
          }
        }
        port_gal{
          img { 
            id
            absolutePath
            size
            publicURL
            childImageSharp {
              gatsbyImageData(width: 480, quality: 85, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`;

